import React from 'react'
import "normalize.css"
import { Link, graphql, useStaticQuery } from 'gatsby'
import footerStyles from './sass/footer.module.scss'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `)
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.row}>
                {/*<div className={footerStyles.vertical}></div>*/}
                <img src={ require("../posts/assets/walter-logo.svg")} alt="full logo" className={footerStyles.footerLogo}/>
                <div className={footerStyles.col2}>
                    <div className={footerStyles.footerNavigation}>
                        <ul className={footerStyles.footerList}>
                            <Link to={'/'} className={footerStyles.footerLink}><li className={footerStyles.footerItem}>Home</li></Link>
                            <Link to={'/blog'} className={footerStyles.footerLink}><li className={footerStyles.footerItem}>Blog</li></Link>
                            <Link to={'/about'} className={footerStyles.footerLink}><li className={footerStyles.footerItem}>About me</li></Link>
                            <Link to={'/contact'} className={footerStyles.footerLink}><li className={footerStyles.footerItem}>Contact</li></Link>
                        </ul>
                    </div>
                </div>
                <div className={footerStyles.col2}>
                   <p className={footerStyles.footerCopyright}>Designed and developed by {data.site.siteMetadata.author}, &copy; {new Date().getFullYear()}</p>
                    <a href="https://github.com/walterloan26" target="_blank" rel="noopener noreferrer"><img src={require('../posts/assets/github.svg')} className={footerStyles.footerImage} alt="github"></img></a>
                    <a href="mailto:walterloancf@gmail.com" target="_blank" rel="noopener noreferrer"><img src={require('../posts/assets/gmail.svg')} className={footerStyles.footerImage} alt="gmail"></img></a>
                    <a href="https://www.linkedin.com/in/walter-de-figueiredo/" target="_blank" rel="noopener noreferrer"><img src={require('../posts/assets/linkdln.png')} className={footerStyles.footerImage} alt="linkdln"></img></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer