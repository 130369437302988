import React from 'react'
import "normalize.css"
import { Link } from 'gatsby'
import headerStyles from './sass/header.module.scss'

const Header = () => {
    // const data = useStaticQuery(graphql`
    //     query {
    //         site {
    //             siteMetadata {
    //                 title
    //                 subtitle
    //             }
    //         }
    //     }
    // `)
    return (
        <header className={headerStyles.header}>
            <nav>
                <ul className={headerStyles.navList}>
                    <li> <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link> </li>
                    <li> <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">Blog</Link> </li>
                    <li> <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/about">About</Link> </li>
                    <li> <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">Contact</Link> </li>
                </ul>
            </nav>
        </header>
    )
}
export default Header